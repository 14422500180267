var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"notifications-table"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":20},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('v-img',{staticStyle:{"height":"50px","width":"50px","margin":"5px"},attrs:{"src":item.image,"aspect-ratio":"1"}})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"m-small",attrs:{"icon":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.info",fn:function(ref){
var item = ref.item;
return [(item.active)?_c('v-btn',{staticClass:"m-small",attrs:{"icon":"","color":"green"}},[_vm._v(" Ativo ")]):_c('v-btn',{staticClass:"m-small",attrs:{"icon":"","color":"red"}},[_vm._v(" Inativo ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"blue"},on:{"click":function($event){return _vm.onCopy(item)}}},[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }