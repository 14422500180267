

























import Vue from "vue";
import { mapGetters } from "vuex";
import FullScreenDialog from "@/components/FullScreenDialog.vue";
import NotificationsForm from "./components/NotificationsForm.vue"
import NotificationsTable from "./components/NotificationsTable.vue"
import { TelegramNotification } from "@/store/types";

export default Vue.extend({
  name: "TelegramNotifications",
  
  components: {
    FullScreenDialog,
    NotificationsForm,
    NotificationsTable
  },

  data: () => ({
    fullScreenDialog: false,
    notification: null as unknown | TelegramNotification
  }),

  computed: {
    ...mapGetters({
      notifications: "telegramNotifications/getNotifications",
    })
  },

  mounted() {
    this.$store.dispatch("telegramNotifications/fetch");
  },

  methods: {
    onCopy(notification: TelegramNotification): void {
      this.notification = notification;
      this.openFullScreenDialog();
    },

    openFullScreenDialog(): void {
      this.fullScreenDialog = true;
    },

    closeFullScreenDialog(): void {
      this.fullScreenDialog = false;
      this.notification = null;
    },
  }
})
